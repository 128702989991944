import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Select from 'react-select';
import HeroImage from "../images/integration-banner.png";  
import GoogleAddImage from "../images/integration-logo/google-add.jpg";  
import FacebookAddImage from "../images/integration-logo/facebook-ads.jpg";  
import AdformAddImage from "../images/integration-logo/adform.jpg";  
import FbAddImage from "../images/integration-logo/fb.jpg";  
import DoubleclickAddImage from "../images/integration-logo/doubleclick.jpg";  
import FbLeadAddImage from "../images/integration-logo/fb-lead.jpg";  
import SegmentAddImage from "../images/integration-logo/segment.jpg";  

import MicrosoftDynamicCrmImage from "../images/integration-logo/microsoft-dynamic-crm.jpg";
import PipedriveTwoImage from "../images/integration-logo/pipedrive1.jpg"; 
import SalesforceImage from "../images/integration-logo/salesforce.jpg"; 
import SalesmateImage from "../images/integration-logo/salesmate.jpg"; 
import YotpoImage from "../images/integration-logo/yotpo.jpg";
import ZohoImage from "../images/integration-logo/zoho.jpg"; 

import AWSImage from "../images/integration-logo/aws.jpg"; 
import AzureImage from "../images/integration-logo/azure.jpg"; 
import GoogleCloudImage from "../images/integration-logo/google-cloud-platform.jpg"; 

import AmazonRedshiftImage from "../images/integration-logo/amazon-redshift.jpg"; 
import GoogleBigQueryImage from "../images/integration-logo/google-bigquery.jpg"; 
import MicrosoftAzureSQLImage from "../images/integration-logo/microsoft-azure-sql.jpg"; 
import MicrosoftSQLServerImage from "../images/integration-logo/microsoft-sql-server.jpg"; 
import MonogoDBImage from "../images/integration-logo/monogodb.jpg"; 
import MySQLImage from "../images/integration-logo/mysql.jpg"; 
import PostgreSQLImage from "../images/integration-logo/postgresql.jpg"; 
import JDBCImage from "../images/integration-logo/jdbc.jpg"; 

import AmazonMWSImage from "../images/integration-logo/amazon-mws.jpg"; 
import BigcommerceImage from "../images/integration-logo/bigcommerce.jpg"; 
import LightspeedImage from "../images/integration-logo/lightspeed.jpg"; 
import MagentoImage from "../images/integration-logo/magento.jpg"; 
import SalesforceB2CCommerceImage from "../images/integration-logo/salesforce-b2c-commerce.jpg"; 
import SAPCloudCommerceImage from "../images/integration-logo/sap-cloud-commerce.jpg";
import ShopifyImage from "../images/integration-logo/shopify.jpg";

import MicrosoftDynamic365Image from "../images/integration-logo/microsoft-dynamic-365.jpg";
import MicrosoftDynamicAXImage from "../images/integration-logo/microsoft-dynamic-ax.jpg";
import NetsuiteImage from "../images/integration-logo/netsuite.jpg";
import OracleJDEOrchestratorImage from "../images/integration-logo/oracle-jde-orchestrator.jpg";
import SAPBusinessRedesignImage from "../images/integration-logo/sap-business-redesign.jpg";
import SAPR3ECCImage from "../images/integration-logo/sap-r3ecc.jpg";

import TwilioImage from "../images/integration-logo/twilio.jpg";

import MailchimpImage from "../images/integration-logo/mailchimp.jpg";
import MailgunImage from "../images/integration-logo/mailgun.jpg";
import MailjetImage from "../images/integration-logo/mailjet.jpg";
import OutlookImage from "../images/integration-logo/outlook.jpg";
import SalesmateoneImage from "../images/integration-logo/salesmate.jpg";

import FacebookImage from "../images/integration-logo/facebook.jpg";
import InstagramImage from "../images/integration-logo/instagram.jpg";
import PinterestImage from "../images/integration-logo/pinterest.jpg";
import TwitterImage from "../images/integration-logo/twitter.jpg";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const IntegrationPage = () => {
    const options = [
        { value: 'b2cretailer', label: 'B2C Retailer' },
        { value: 'b2bdistributor/manufacturer', label: 'B2B Distributor/Manufacturer' },
        { value: 'agency', label: 'Agency' },
        { value: 'other', label: 'Other' }
    ]
    return (
        <div className="template-partner header-position header-white">
            <Layout>
                <div className="hero-banner integration-banner mb-208">
                    <div className="container">  
                        <div className="content-main text-center">
                            <div className="banner-title-block">
                                <div className="banner-text"> 
                                    <h2>Integrations</h2>
                                    <p className="mb-48">Enjoy additional functionalities by integrating your desired app so that you <br /> can excel and grow your commerce, content, and customers.</p>
                                </div>
                            </div>
                            <div className="banner-image">
                                <img loading="lazy" src={HeroImage} title="Integrations" alt="Integrations" />
                            </div>
                        </div>
                    </div>
                </div>
             

                <div className="category-listing-main mb-208">
                    <div className="container">
                        <div className="category-listing-inner">
                            <Tabs className="d-flex flex-wrap">
                                <div className="category-title"><h5>Explore by Category</h5></div>
                                <TabList className="category-sidebar d-flex">
                                    <Tab className="small-text">Advertising <span>(6)</span></Tab>
                                    <Tab className="small-text">Analytics <span>(1)</span></Tab>
                                    <Tab className="small-text">CRM <span>(6)</span></Tab> 
                                    <Tab className="small-text">Cloud <span>(3)</span></Tab>
                                    <Tab className="small-text">Databbase <span>(8)</span></Tab>
                                    <Tab className="small-text">eCommerce <span>(7)</span></Tab>
                                    <Tab className="small-text">ERP <span>(6)</span></Tab>
                                    <Tab className="small-text">Email Marketing <span>(5)</span></Tab>
                                    <Tab className="small-text">Social Media <span>(4)</span></Tab> 
                                    <Tab className="small-text">SMS <span>(1)</span></Tab> 
                                </TabList>

                                <TabPanel>
                                    <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                            <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                <span className="add-text font-capital font-medium">Advertising</span>
                                                <span className="add-img-name d-flex">
                                                    <img loading="lazy" src={GoogleAddImage} title="Google Add" alt="Google Add" />
                                                    <b className="font-medium add-name mt-24">Google Ads</b>
                                                </span>
                                            </Link> 
                                        </div>
                                        
                                        <div className="grid mb-32"> 
                                            <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                <span className="add-text font-capital font-medium">Advertising</span>
                                                <span className="add-img-name d-flex">
                                                    <img loading="lazy" src={FacebookAddImage} title="Facebook Ads" alt="Facebook Ads" />
                                                    <b className="font-medium add-name mt-24">Facebook Ads </b>
                                                </span>
                                            </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                            <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                <span className="add-text font-capital font-medium">Advertising</span>
                                                <span className="add-img-name d-flex">
                                                    <img loading="lazy" src={AdformAddImage} title="adForm" alt="adForm" />
                                                    <b className="font-medium add-name mt-24">adForm</b>
                                                </span>
                                            </Link> 
                                        </div>

                                        <div className="grid "> 
                                            <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                <span className="add-text font-capital font-medium">Advertising</span>
                                                <span className="add-img-name d-flex">
                                                    <img loading="lazy" src={FbAddImage} title="Facebook Conversion API" alt="Facebook Conversion API" />
                                                    <b className="font-medium add-name mt-24">Facebook Conversion API</b>
                                                </span>
                                            </Link> 
                                        </div>
                                        
                                        <div className="grid "> 
                                            <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                <span className="add-text font-capital font-medium">Advertising</span>
                                                <span className="add-img-name d-flex">
                                                    <img loading="lazy" src={DoubleclickAddImage} title="Google Double Click" alt="Google Double Click" />
                                                    <b className="font-medium add-name mt-24">Google Double Click</b>
                                                </span>
                                            </Link> 
                                        </div>

                                        <div className="grid "> 
                                            <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                <span className="add-text font-capital font-medium">Advertising</span>
                                                <span className="add-img-name d-flex">
                                                    <img loading="lazy" src={FbLeadAddImage} title="Facebook Lead Ads" alt="Facebook Lead Ads" />
                                                    <b className="font-medium add-name mt-24">Facebook Lead Ads</b>
                                                </span>
                                            </Link> 
                                        </div> 
                                    </div>
                                </TabPanel>
                                
                                <TabPanel>
                                    <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid"> 
                                            <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                <span className="add-text font-capital font-medium">Analytics</span>
                                                <span className="add-img-name d-flex">
                                                    <img loading="lazy" src={SegmentAddImage} title="Segment.io" alt="Segment.io" />
                                                    <b className="font-medium add-name mt-24">Segment.io</b>
                                                </span>
                                            </Link> 
                                        </div> 
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                    <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">CRM</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MicrosoftDynamicCrmImage} title="Microsoft Dynamic CRM" alt="Microsoft Dynamic CRM" />
                                                        <b className="font-medium add-name mt-24">Microsoft Dynamic CRM</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid "> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">CRM</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={PipedriveTwoImage} title=" Pipedrive" alt="Pipedrive" />
                                                        <b className="font-medium add-name mt-24">Pipedrive</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">CRM</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={SalesforceImage} title="Salesforce" alt="Salesforce" />
                                                        <b className="font-medium add-name mt-24">Salesforce</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">CRM</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={SalesmateImage} title="Salesmate" alt="Salesmate" />
                                                        <b className="font-medium add-name mt-24">Salesmate</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">CRM</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={YotpoImage} title="Yotpo" alt="Yotpo" />
                                                        <b className="font-medium add-name mt-24">Yotpo</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">CRM</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={ZohoImage} title="Zoho" alt="Zoho" />
                                                        <b className="font-medium add-name mt-24">Zoho</b>
                                                    </span>
                                                </Link> 
                                        </div>
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Cloud</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={AWSImage} title="AWS " alt="AWS" />
                                                        <b className="font-medium add-name mt-24">AWS</b>
                                                    </span>
                                                </Link> 
                                        </div>
                                        
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Cloud</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={AzureImage} title="Azure" alt="Azure" />
                                                        <b className="font-medium add-name mt-24">Azure </b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Cloud</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={GoogleCloudImage} title="Google Cloud Platform" alt="Google Cloud Platform" />
                                                        <b className="font-medium add-name mt-24">Google Cloud Platform</b>
                                                    </span>
                                                </Link> 
                                        </div> 
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Databbase</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={AmazonRedshiftImage} title="Amazon Redshift" alt="Amazon Redshift" />
                                                        <b className="font-medium add-name mt-24">Amazon Redshift</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Databbase</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={GoogleBigQueryImage} title="Google BigQuery" alt="Google BigQuery" />
                                                        <b className="font-medium add-name mt-24">Google BigQuery</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Databbase</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={JDBCImage} title="JDBC" alt="JDBC" />
                                                        <b className="font-medium add-name mt-24">JDBC</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Databbase</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MicrosoftAzureSQLImage} title="Microsoft Azure SQL " alt=" Microsoft Azure SQL" />
                                                        <b className="font-medium add-name mt-24">Microsoft Azure SQL</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Databbase</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MicrosoftSQLServerImage} title="Microsoft SQL Server" alt="Microsoft SQL Server" />
                                                        <b className="font-medium add-name mt-24">Microsoft SQL Server</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Databbase</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MonogoDBImage} title="MonogoDB" alt="MonogoDB" />
                                                        <b className="font-medium add-name mt-24">MonogoDB</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Databbase</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MySQLImage} title="MySQL" alt="MySQL" />
                                                        <b className="font-medium add-name mt-24">MySQL</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Databbase</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={PostgreSQLImage} title="PostgreSQL" alt="PostgreSQL" />
                                                        <b className="font-medium add-name mt-24">PostgreSQL</b>
                                                    </span>
                                                </Link> 
                                        </div>
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                    <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">eCommerce</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={AmazonMWSImage} title="AmazonMWS" alt="AmazonMWS" />
                                                        <b className="font-medium add-name mt-24">AmazonMWS</b>
                                                    </span>
                                                </Link> 
                                        </div>
                                        
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">eCommerce</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={BigcommerceImage} title="Bigcommerce" alt="Bigcommerce" />
                                                        <b className="font-medium add-name mt-24">Bigcommerce</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                         <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">eCommerce</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={LightspeedImage} title="Lightspeed" alt="Lightspeed" />
                                                        <b className="font-medium add-name mt-24">Lightspeed</b>
                                                    </span>
                                                </Link> 
                                        </div>
                                        
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">eCommerce</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MagentoImage} title="Magento" alt="Magento " />
                                                        <b className="font-medium add-name mt-24">Magento </b>
                                                    </span>
                                                </Link> 
                                        </div>

                                             <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">eCommerce</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={SalesforceB2CCommerceImage} title="SalesforceB2CCommerce" alt="SalesforceB2CCommerce" />
                                                        <b className="font-medium add-name mt-24">Salesforce B2C Commerce </b>
                                                    </span>
                                                </Link> 
                                        </div>

                                             <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">eCommerce</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={SAPCloudCommerceImage} title=" SAPCloudCommerce" alt=" SAPCloudCommerce" />
                                                        <b className="font-medium add-name mt-24">SAP Cloud Commerce</b>
                                                    </span>
                                                </Link> 
                                        </div>

                                             <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">eCommerce</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={ShopifyImage} title="Shopify" alt=" Shopify" />
                                                        <b className="font-medium add-name mt-24">Shopify</b>
                                                    </span>
                                                </Link> 
                                        </div> 
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">ERP</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MicrosoftDynamic365Image} title="MicrosoftDynamic365" alt="MicrosoftDynamic365" />
                                                        <b className="font-medium add-name mt-24">Microsoft Dynamic 365</b>
                                                    </span>
                                                </Link> 
                                        </div>
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">ERP</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MicrosoftDynamicAXImage} title="MicrosoftDynamicAX" alt="MicrosoftDynamicAX" />
                                                        <b className="font-medium add-name mt-24">Microsoft Dynamic AX</b>
                                                    </span>
                                                </Link> 
                                        </div>  
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">ERP</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={NetsuiteImage} title="Netsuite" alt="Netsuite" />
                                                        <b className="font-medium add-name mt-24">Netsuite</b>
                                                    </span>
                                                </Link> 
                                        </div>  
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">ERP</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={OracleJDEOrchestratorImage} title="OracleJDEOrchestrator" alt="OracleJDEOrchestrator" />
                                                        <b className="font-medium add-name mt-24">Oracle JDE Orchestrator</b>
                                                    </span>
                                                </Link> 
                                        </div>  
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">ERP</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={SAPBusinessRedesignImage} title="SAPBusinessRedesign" alt="SAPBusinessRedesign" />
                                                        <b className="font-medium add-name mt-24">SAP Business Redesign</b>
                                                    </span>
                                                </Link> 
                                        </div>  
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">ERP</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={SAPR3ECCImage} title="SAPR3ECCImage" alt="SAPR3ECCImage" />
                                                        <b className="font-medium add-name mt-24">SAP R3/ECC</b>
                                                    </span>
                                                </Link> 
                                        </div>  
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                    <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Email Marketing</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MailchimpImage} title="Mailchimp" alt="Mailchimp" />
                                                        <b className="font-medium add-name mt-24">Mailchimp</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Email Marketing</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MailgunImage} title="Mailgun" alt="Mailgun" />
                                                        <b className="font-medium add-name mt-24">Mailgun</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Email Marketing</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={MailjetImage} title="Mailjet" alt="Mailjet" />
                                                        <b className="font-medium add-name mt-24">Mailjet</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Email Marketing</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={OutlookImage} title="Outlook" alt="Outlook" />
                                                        <b className="font-medium add-name mt-24">Outlook</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Email Marketing</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={SalesmateoneImage} title="Salesmateone" alt="Salesmateone" />
                                                        <b className="font-medium add-name mt-24">Salesmate</b>
                                                    </span>
                                                </Link> 
                                        </div> 
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                    <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Social Media</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={FacebookImage} title="Facebook" alt="Facebook" />
                                                        <b className="font-medium add-name mt-24">Facebook</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Social Media</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={InstagramImage} title="Instagram" alt="Instagram" />
                                                        <b className="font-medium add-name mt-24">Instagram</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Social Media</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={PinterestImage} title="Pinterest" alt="Pinterest" />
                                                        <b className="font-medium add-name mt-24">Pinterest</b>
                                                    </span>
                                                </Link> 
                                        </div> 

                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">Social Media</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={TwitterImage} title="Twitter" alt="Twitter " />
                                                        <b className="font-medium add-name mt-24">Twitter</b>
                                                    </span>
                                                </Link> 
                                        </div> 
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                    <div className="tab-detail-inner d-flex flex-wrap custom-row row-3">
                                        <div className="grid mb-32"> 
                                                <Link className="cat-item font-light-color d-flex " to="#integration-form">
                                                    <span className="add-text font-capital font-medium">SMS</span>
                                                    <span className="add-img-name d-flex">
                                                        <img loading="lazy" src={TwilioImage} title="Twilio" alt="Twilio" />
                                                        <b className="font-medium add-name mt-24">Twilio</b>
                                                    </span>
                                                </Link> 
                                        </div> 
                                    </div>
                                </TabPanel>

                            </Tabs>
                        </div> 
                    </div>
                </div>
                


                <div className="content-form-block integration-form mb-208" id="integration-form">
                    <div className="container-medium">
                        <div className="content-form-inner custom-row align-v-center row-2 d-flex flex-wrap">
                            <div className="content-block grid"> 
                                <h3 class="h2" className="mb-48">Integrations for Experro</h3>
                                <p>Contact us for your integration requirements or tell us which one is missing, and we'll get back to you. </p>
                            </div>
                            <div className="form-block grid">
                                <div className="form-inner">
                                    <h4 className="link-color">Integrations</h4>
                                    <p className="font-light-color small-text">More connections, greater experiences!</p>
                                
                                    <form>
                                        <div className="custom-row row-2 d-flex flex-wrap">
                                            <div className="grid mb-24">
                                                <input type="text" placeholder="First name"></input>
                                            </div>
                                            <div className="grid mb-24">
                                                <input type="text" placeholder="Second name"></input>
                                            </div>
                                            <div className="grid mb-24">
                                                <input type="text" placeholder="Company name*"></input>
                                            </div>
                                            <div className="grid mb-24">
                                                <input type="email" placeholder="Company email*"></input>
                                            </div>
                                            <div className="grid mb-24 full">
                                                <input type="text" placeholder="Job title*"></input>
                                            </div>

                                            <div className="grid mb-24 full">
                                                <Select className="rect-select" placeholder="I am from" options={options} />
                                            </div>

                                            <div className="grid full mb-24">
                                                <input type="text" placeholder="Integration name*"></input>
                                            </div>
                                            <div className="grid full mb-24">
                                                <input type="text" placeholder="What are you trying to accomplish with this integration?*"></input>
                                            </div>

                                            <div className="grid full mb-24">
                                                <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"></input>
                                                <label className="small-text" for="styled-checkbox-1">I’d like updates on Experro news, events and services (see <Link href="/privacy-policy/"> Privarcy policy</Link>).</label>
                                            </div>

                                            <div className="grid full">
                                                <input type="submit" className="button black-button" value="Get in Touch"></input>
                                            </div> 
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </Layout>
        </div>
        
    );
}

export default IntegrationPage;